<template>
  <div>
    <h1 class="mr-sm-4 header-tablepage mb-3">Option system</h1>

    <b-tabs ref="b_tabs" v-model="tabIndex">
      <b-tab title="General">
        <div class="title-tabs">Coupon Use By Customer</div>
        <div class="bg-white p-2 p-lg-3">
          <div class="row">
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Invoice No. for Customer Use Coupon"
                name="invoice_no"
                placeholder=""
                type="text"
                v-model="form.invoice_no_customer_coupon"
                @onKeypress="checkText"
              />
            </div>

            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Invoice No for Customer User coupon
              </div>
              <div class="text-black">
                Invoice No when customer use coupon from customer side.
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <InputText
                textFloat="Customer User Product (Article No.)"
                name="article_no"
                placeholder=""
                type="text"
                v-model="form.customer_user_coupon_product"
                @onKeypress="checkText"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Customer User Product (Article No.)
              </div>
              <div class="text-black">
                This product will apply automatically in transaction when
                customer use coupon from customer side.
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <InputText
                textFloat="Customer Use Coupon Sale (Sale ID.)"
                name="sale_id"
                placeholder=""
                type="text"
                v-model="form.customer_user_coupon_sale"
                @onKeypress="checkText"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Customer User Coupon Sales (Sale ID)
              </div>
              <div class="text-black">
                This sale will apply automatically in transaction when customer
                user coupon from customer side.
              </div>
            </div>
          </div>
        </div>
        <div class="title-tabs">Customer Life Cycle</div>
        <div class="bg-white p-2 p-lg-3">
          <div class="row">
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Prospect to Inactive Prospect (Day)"
                name="x_day_prospect_inactive"
                placeholder=""
                :v="$v.form.x_day_prospect_inactive"
                :isValidate="$v.form.x_day_prospect_inactive.$error"
                type="number"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_prospect_inactive"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Prospect to Inactive Prospect (Day)
              </div>
              <div class="text-black">
                a prospect customer will be designated as inactive prospect if
                they do note complete a transaction within a specified number of
                days since becoming a prospect.
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="First Purchase Customer to Inactive Customer (Day)"
                name="x_day_first_lapse"
                placeholder=""
                :v="$v.form.x_day_first_lapse"
                :isValidate="$v.form.x_day_first_lapse.$error"
                type="number"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_first_lapse"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                First Purchase customer to Inactive Customer (Day)
              </div>
              <div class="text-black">
                a first purchase customer will be designated as inactive
                customer if they do not complete a transaction within a
                specified number of days since becoming a first purchase
                customer.
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Active Customer to Lapse Customer (Day)"
                name="x_day_active_lapse"
                placeholder=""
                type="number"
                :v="$v.form.x_day_active_lapse"
                :isValidate="$v.form.x_day_active_lapse.$error"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_active_lapse"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Active customer to Lapse customer (Day)
              </div>
              <div class="text-black">
                a active customer will be designated as Lapse if they do not
                complete a transaction within a specified number of days since
                becoming a active customer.
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Lapse Customer to Inactive Customer (Day)"
                name="x_day_lapse_inactive"
                placeholder=""
                :v="$v.form.x_day_lapse_inactive"
                :isValidate="$v.form.x_day_lapse_inactive.$error"
                type="number"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_lapse_inactive"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Lapse customer to Inactive customer (Day)
              </div>
              <div class="text-black">
                a Lapse customer will be designated as inactive prospect if they
                do note complete a transaction within a specified number of days
                since becoming a Lapse customer.
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="First Purchase to Active Customer (Expected Repurchase Day)"
                name="x_day_first_purchase"
                placeholder=""
                :v="$v.form.x_day_first_purchase"
                :isValidate="$v.form.x_day_first_purchase.$error"
                type="number"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_first_purchase"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Active Customer to Lapse Customer (Expected Repurchase Day)"
                name="x_day_active"
                placeholder=""
                :v="$v.form.x_day_active"
                :isValidate="$v.form.x_day_active.$error"
                type="number"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_active"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Lapse Customer to Inactive Customer (Expected Win back Day)"
                name="x_day_lapse"
                placeholder=""
                :v="$v.form.x_day_lapse"
                :isValidate="$v.form.x_day_lapse.$error"
                type="number"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_lapse"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Inactive Customer to Active Customer (Expected Win back Day)"
                name="x_day_inactive"
                placeholder=""
                :v="$v.form.x_day_inactive"
                :isValidate="$v.form.x_day_inactive.$error"
                type="number"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_inactive"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
          </div>
        </div>

        <div class="title-tabs">Privacy Policy</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12">
              <b-form-checkbox
                class="my-2"
                name="custom-link"
                id="custom-link"
                @change="clearField($event, ['consent_url_link'])"
                v-model="form.is_custom_privacy_link"
                value="1"
                unchecked-value="0"
              >
                Custom Privacy Policy Link
              </b-form-checkbox>
            </b-col>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Privacy Policy Link"
                name="custom-link"
                :placeholder="
                  form.is_custom_privacy_link == 0
                    ? ''
                    : 'Please Insert Link (example : https://www.example.com)'
                "
                :v="$v.form.consent_url_link"
                :isValidate="$v.form.consent_url_link.$error"
                type="text"
                :disabled="form.is_custom_privacy_link == 0"
                v-model="$v.form.consent_url_link.$model"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
          </div>
          <div class="row">
            <b-col cols="12">
              <b-form-checkbox
                class="my-2"
                name="custom-term-link"
                id="custom-term-link"
                @change="clearField($event, ['term_and_con_url'])"
                v-model="form.is_term_and_con_url"
                value="1"
                unchecked-value="0"
              >
                Custom Terms and Conditions Link
              </b-form-checkbox>
            </b-col>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Terms and Conditions Link"
                name="custom-term-link"
                :placeholder="
                  form.is_term_and_con_url == 0
                    ? ''
                    : 'Please Insert Link (example : https://www.example.com)'
                "
                :v="$v.form.term_and_con_url"
                :isValidate="$v.form.term_and_con_url.$error"
                type="text"
                :disabled="form.is_term_and_con_url == 0"
                v-model="$v.form.term_and_con_url.$model"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
          </div>
          <div class="row">
            <b-col cols="12">
              <b-form-checkbox
                class="my-2"
                name="custom-right-link"
                id="custom-right-link"
                @change="clearField($event, ['right_request_url'])"
                v-model="form.is_right_request_url"
                value="1"
                unchecked-value="0"
              >
                Custom Right Request Link
              </b-form-checkbox>
            </b-col>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Right Request Link"
                name="custom-right-link"
                :placeholder="
                  form.is_right_request_url == 0
                    ? ''
                    : 'Please Insert Link (example : https://www.example.com)'
                "
                :v="$v.form.right_request_url"
                :isValidate="$v.form.right_request_url.$error"
                type="text"
                :disabled="form.is_right_request_url == 0"
                v-model="$v.form.right_request_url.$model"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
          </div>
        </div>
        <div class="title-tabs">Point System</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio
              v-model="form.point_system"
              :aria-describedby="ariaDescribedby"
              name="point-selected"
              value="1"
              >Center Point</b-form-radio
            >
            <b-form-radio
              v-model="form.point_system"
              :aria-describedby="ariaDescribedby"
              name="point-selected"
              value="0"
              >Branch Point</b-form-radio
            >
          </b-form-group>
        </div>
        <div class="title-tabs">Single Sign on (SSO)</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12">
              <b-form-checkbox
                class="my-2"
                name="single-sign-on"
                id="single-sign-on"
                v-model="form.enable_single_sign_on_azure_active_directory"
                value="1"
                unchecked-value="0"
              >
                Enable Single Sign on Azure Active Directory
              </b-form-checkbox>
            </b-col>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Client ID"
                name="client-id"
                placeholder="Client ID"
                :v="$v.form.client_id"
                :isRequired="
                  form.enable_single_sign_on_azure_active_directory == 1
                "
                :isValidate="$v.form.client_id.$error"
                type="text"
                :disabled="
                  form.enable_single_sign_on_azure_active_directory == 0
                "
                v-model="$v.form.client_id.$model"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Endpoint"
                name="endpoint"
                :placeholder="
                  form.enable_single_sign_on_azure_active_directory == 0
                    ? ''
                    : 'Please Insert Link (example : https://www.example.com)'
                "
                :isRequired="
                  form.enable_single_sign_on_azure_active_directory == 1
                "
                :v="$v.form.azure_endpoint"
                :isValidate="$v.form.azure_endpoint.$error"
                :disabled="
                  form.enable_single_sign_on_azure_active_directory == 0
                "
                v-model="$v.form.azure_endpoint.$model"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="title-tabs">Bundle Product Recommendation</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col>
              <InputText
                textFloat="Bundle Calculation Period (0-365)"
                name="bundle_culculation"
                placeholder=""
                type="number"
                v-model="form.bundle_calculation_day"
              />
            </b-col>
          </div>
        </div>
        <div class="title-tabs">Import Transaction</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <b-form-checkbox
            name="import-transaction-is-update-product"
            id="import-transaction-is-update-product"
            value="1"
            v-model="setting.import_transaction_is_update_product"
            unchecked-value="0"
          >
            Auto Update Product When Import Transaction
          </b-form-checkbox>
        </div>
        <div class="title-tabs">Profile Complete</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12">
              <b-form-checkbox
                class="my-2"
                name="display-profile-complete"
                id="display-profile-complete"
                value="1"
                v-model="setting.display_profile_complete"
                unchecked-value="0"
              >
                Display Profile Complete
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="6">
              <InputTextArea
                textFloat="Description"
                name="profile-complete-description"
                placeholder=""
                v-model="setting.profile_complete_description"
                type="text"
                isRequired
                :disabled="setting.display_profile_complete == '0'"
                :v="$v.setting.profile_complete_description"
                :isValidate="$v.setting.profile_complete_description.$error"
              />
            </b-col>
            <div class="break-normal"></div>
            <b-col cols="12" md="6">
              <InputSelect
                title="When complete do automation"
                name="complate-automation"
                valueField="id"
                textField="name"
                :disabled="setting.display_profile_complete == '0'"
                :options="automationList"
                v-model="setting.profile_complete_automation"
              >
                <template v-slot:option-first>
                  <b-form-select-option value=""
                    >-- No trigger automation --</b-form-select-option
                  >
                </template></InputSelect
              ></b-col
            >
          </div>
        </div>
        <div class="title-tabs">Point Expire</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12" md="6">
              <InputText
                textFloat="Point Reminder (Days)"
                name="point_reminder"
                placeholder=""
                :v="$v.form.point_reminder"
                :isValidate="$v.form.point_reminder.$error"
                type="number"
                oninput="if( this.value.length > 4 )  this.value = this.value.slice(0,4)"
                isRequired
                v-model="form.point_reminder"
              />
            </b-col>
          </div>
        </div>
        <div class="title-tabs">Customer</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12" md="6">
              <b-form-checkbox
                class="my-2"
                name="is-company"
                id="is-company"
                value="1"
                v-model="form.company_account"
                @change="() => (form.show_attached_document_on_register = 0)"
                unchecked-value="0"
              >
                Company Account
              </b-form-checkbox>
            </b-col>
            <div class="break-normal"></div>
            <b-col cols="12" md="6">
              <b-form-checkbox
                class="my-2"
                name="show-attached-document"
                id="show-attached-document"
                value="1"
                v-model="form.show_attached_document_on_register"
                unchecked-value="0"
                :disabled="form.company_account == 0"
              >
                Show Attached Document on Register (Company Only)
              </b-form-checkbox>
            </b-col>
            <div class="break-normal"></div>
            <b-col cols="12" md="6">
              <b-form-checkbox
                class="my-2"
                name="unit-per-address"
                id="unit-per-address"
                value="1"
                v-model="checkAddress"
                unchecked-value="0"
                @change="clearAddress"
              >
                Billing Address, Shipping Address, Contact Address
              </b-form-checkbox>
            </b-col>
            <b-col md="4" cols="12">
              <InputText
                textFloat=""
                name="unit-per-address"
                placeholder=""
                type="number"
                class="mb-0"
                v-model="form.address_per_unit"
                :disabled="checkAddress == 0"
                :v="$v.form.address_per_unit"
                :isValidate="$v.form.address_per_unit.$error"
              />
            </b-col>
            <b-col cols="12" md="6">
              <b-form-checkbox
                class="my-2"
                name="limit-telephone-checkbox"
                id="limit-telephone-checkbox"
                value="1"
                v-model="checkTelephone"
                unchecked-value="0"
                @change="clearTelephone"
              >
                Limit Secondary Telephone
              </b-form-checkbox>
            </b-col>
            <b-col md="4" cols="12">
              <InputText
                textFloat=""
                name="limit-telephone"
                placeholder=""
                type="number"
                class="mb-0"
                v-model="form.limit_secondary_telephone"
                :disabled="checkTelephone == 0"
                :v="$v.form.limit_secondary_telephone"
                :isValidate="$v.form.limit_secondary_telephone.$error"
              />
            </b-col>
          </div>
        </div>
        <div class="title-tabs">Dashboard</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12" md="6">
              <b-form-checkbox
                class="my-2"
                name="show_dashboard_tooltips"
                id="show_dashboard_tooltips"
                value="1"
                v-model="form.show_dashboard_tooltips"
                unchecked-value="0"
              >
                Show Tooltips
              </b-form-checkbox>
            </b-col>
          </div>
        </div>
        <div class="title-tabs">Upgrade Member Tier</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12" md="6">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio
                  v-model="form.upgrade_when_expired"
                  :aria-describedby="ariaDescribedby"
                  name="upgrade-member-tier"
                  value="1"
                  >When Member Tier Expire</b-form-radio
                >
                <b-form-radio
                  v-model="form.upgrade_when_expired"
                  :aria-describedby="ariaDescribedby"
                  name="upgrade-member-tier"
                  value="0"
                  >When Reach Upgrade</b-form-radio
                >
              </b-form-group>
            </b-col>
          </div>
        </div>
        <div class="title-tabs">Register</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12" md="6">
              <b-form-checkbox
                class="my-2"
                name="allow_only_member_on_line_login"
                id="allow_only_member_on_line_login"
                value="1"
                v-model="form.allow_only_member_on_line_login"
                unchecked-value="0"
              >
                Allow Only Member On Login Line
              </b-form-checkbox>
            </b-col>
          </div>
        </div>

        <div class="title-tabs">Branch</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12">
              <b-form-checkbox
                class="my-2"
                name="require_pin_login "
                id="require_pin_login"
                value="1"
                v-model="form.require_pin_login"
                @change="clearPin"
                unchecked-value="0"
              >
                Required Pin Login (Minute)
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="6">
              <InputText
                textFloat="Time Lock Pin (Minute)"
                placeholder="Time Lock Pin "
                type="number"
                name=""
                :disabled="
                  form.require_pin_login == 0 || !form.require_pin_login
                "
                v-model="form.time_lock_pin"
                :v="$v.form.time_lock_pin"
                :isValidate="$v.form.limit_secondary_telephone.$error"
              ></InputText>
            </b-col>
          </div>
        </div>
        <div class="title-tabs">Ticket</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12" md="6">
              <b-form-checkbox
                id="default_show_assign_to_me"
                v-model="form.default_show_assign_to_me"
                :value="1"
                :unchecked-value="0"
                name="allowe-change"
              >
                <b>Default Show Only Assign To Me</b>
              </b-form-checkbox>
            </b-col>
          </div>
        </div>
        <div class="title-tabs">Transaction</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12" md="12">
              <b-form-checkbox
                id="void_return_to_store_credit"
                v-model="form.void_return_to_store_credit"
                :value="1"
                :unchecked-value="0"
                name="void_return_to_store_credit"
              >
                Void/Return to Store Credit
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="6">
              <InputText
                textFloat="Maximum day to return (Day)"
                placeholder="Maximum day to return (Day)"
                type="number"
                name=""
                class="mt-2"
                :disabled="!form.void_return_to_store_credit"
                v-model="form.allow_time_change_day"
              ></InputText>
            </b-col>
         
          </div>
        </div>
      </b-tab>
      <b-tab title="RFM">
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Calculate Period (Day)"
                name="calculate_period"
                placeholder=""
                :v="$v.form.calculate_period"
                :isValidate="$v.form.calculate_period.$error"
                type="number"
                oninput="if( this.value.length > 4 )  this.value = this.value.slice(0,4)"
                isRequired
                v-model="form.calculate_period"
              />
              <b-button variant="custom-link" @click="handleCalPeriod()">
                Recalculate
              </b-button>
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">Calculate period (Day)</div>
              <div class="text-black">
                The Recency-Frequency-Monetary (RFM) and Repuchase Purchase
                analysis will be performed by determining the time interval
                between the present moment and a specific past date
                <br />
                The Recency-Frequency-Monetary (RFM) analysis is conducted on a
                daily basis
              </div>
            </div>
          </div>
          <hr />
          <b-tabs
            v-model="subTabIndex"
            content-class="px-4 mt-3"
            v-if="isRFMLoading"
          >
            <b-tab v-for="(tab, index) of RFMTab" :key="tab" :title="tab">
              <template v-if="rfmScale">
                <b-row
                  v-for="(scale, n) of rfmScale[index].rfm_range_level"
                  :key="scale.rate_scale + '-' + index"
                >
                  <b-col cols="4" md="3" lg="2">
                    <InputText
                      :textFloat="n == 0 ? 'Score' : ''"
                      :name="'score' + n + index"
                      placeholder=""
                      type="number"
                      :disabled="true"
                      v-model="scale.rate_scale"
                    />
                  </b-col>
                  <b-col cols="4" md="3" lg="2">
                    <InputText
                      :textFloat="n == 0 ? 'From' : ''"
                      :name="'from' + n + index"
                      placeholder=""
                      type="number"
                      :useDot="false"
                      v-model="scale.st_range"
                    />
                  </b-col>
                  <b-col cols="4" md="3" lg="2">
                    <InputText
                      :textFloat="n == 0 ? 'To' : ''"
                      :name="'to' + n + index"
                      placeholder=""
                      type="number"
                      :useDot="false"
                      v-model="scale.ed_range"
                    />
                  </b-col>
                </b-row>
                <hr />
                <label class="main-label">X Axis</label>

                <b-row
                  v-for="(range, n) of rfmScale[index].rfm_range_rate"
                  :key="'range-' + range.rate_scale + '-' + index"
                >
                  <b-col cols="4" md="3" lg="2">
                    <InputText
                      :textFloat="n == 0 ? 'Order' : ''"
                      :name="'score-rate' + n + index"
                      placeholder=""
                      type="number"
                      :disabled="true"
                      :useDot="false"
                      v-model="range.rate_scale"
                    />
                  </b-col>

                  <b-col cols="4" md="3" lg="2">
                    <InputText
                      :textFloat="n == 0 ? 'From' : ''"
                      :name="'from-rate' + n + index"
                      placeholder=""
                      type="number"
                      :useDot="false"
                      :v="
                        $v.rfmScale.$each.$iter[index].rfm_range_rate.$each
                          .$iter[n].st_range
                      "
                      :isValidate="
                        $v.rfmScale.$each.$iter[index].rfm_range_rate.$each
                          .$iter[n].st_range.$error
                      "
                      v-model="range.st_range"
                    />
                  </b-col>
                  <b-col cols="4" md="3" lg="2">
                    <InputText
                      :textFloat="n == 0 ? 'To' : ''"
                      :name="'to-rate-' + n + index"
                      placeholder=""
                      type="number"
                      v-model="range.ed_range"
                      :useDot="false"
                      :v="
                        $v.rfmScale.$each.$iter[index].rfm_range_rate.$each
                          .$iter[n].ed_range
                      "
                      :isValidate="
                        $v.rfmScale.$each.$iter[index].rfm_range_rate.$each
                          .$iter[n].ed_range.$error
                      "
                      :disabled="n + 1 == rfmScale[index].rfm_range_rate.length"
                      decimalPoint="1"
                    />
                  </b-col>
                  <b-col v-if="n != 0">
                    <b-button
                      variant="link"
                      @click="deleteRangeScale(n, index)"
                      class="text-gray"
                    >
                      <font-awesome-icon icon="trash-alt" class="ft-14 ml-2" />
                    </b-button>
                  </b-col>
                </b-row>
              </template>

              <b-button
                v-if="rfmScale[index].rfm_range_rate.length < 20"
                variant="custom-link"
                @click="addRangeScale(index)"
                >Add Graph</b-button
              >
            </b-tab>
          </b-tabs>
        </div>
      </b-tab>
      <b-tab title="Customer Point Scale">
        <div class="bg-white p-2 p-lg-3 text-black">
          <SettingRedemtionRate v-if="tabIndex == 2" ref="settingRedemtion" />
        </div>
      </b-tab>
    </b-tabs>

    <FooterAction @submit="submit" routePath="/setting" />
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import SettingRedemtionRate from "./SettingRedemtionRate";
const isValidUrl = (string, params, form) => {
  try {
    if (params == "policy" && form.is_custom_privacy_link == 0) return true;
    if (params == "term" && form.is_term_and_con_url == 0) return true;
    if (params == "right" && form.is_right_request_url == 0) return true;
    if (
      params == "azure" &&
      form.enable_single_sign_on_azure_active_directory == 0
    )
      return true;

    new URL(string);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};
export default {
  components: {
    SettingRedemtionRate,
  },
  data() {
    return {
      RFMTab: [
        "Recency",
        "Frequency",
        "Montery",
        "Return Purchase",
        "Total Spend",
      ],
      tabIndex: 0,
      checkAddress: 0,
      checkTelephone: 0,
      form: {
        customer_user_coupon_product: "",
        customer_user_coupon_sale: "",
        invoice_no_customer_coupon: "",
        x_day_prospect_inactive: "",
        x_day_first_lapse: "",
        x_day_active_lapse: "",
        x_day_lapse_inactive: "",
        x_day_active: "",
        x_day_first_purchase: "",
        x_day_inactive: "",
        x_day_lapse: "",
        calculate_period: "",
        is_custom_privacy_link: "0",
        consent_url_link: "",
        is_term_and_con_url: "0",
        term_and_con_url: "",
        is_right_request_url: "0",
        right_request_url: "",
        point_system: "0",
        enable_single_sign_on_azure_active_directory: "0",
        client_id: "",
        azure_endpoint: "",
        bundle_calculation_day: 0,
        allow_only_member_on_line_login: 0,
        require_pin_login: null,
        time_lock_pin: 0,
        default_show_assign_to_me: 0,
        void_return_to_stote_credit: 0,
        allow_time_change_day:null
      },
      rfmScale: [],
      isRFMLoading: false,
      automationList: [],
      setting: this.$store.state.theme.themeLogo.result,
      subTabIndex: 0,
    };
  },
  validations() {
    return {
      form: {
        x_day_prospect_inactive: { required },
        x_day_first_lapse: { required },
        x_day_active_lapse: { required },
        x_day_lapse_inactive: { required },
        calculate_period: { required },
        point_reminder: { required },
        x_day_active: { required },
        x_day_first_purchase: { required },
        x_day_inactive: { required },
        x_day_lapse: { required },
        consent_url_link: {
          isValidUrl: (val, form) => isValidUrl(val, "policy", form),
          required: requiredIf(function () {
            return this.form.is_custom_privacy_link == 1;
          }),
        },
        address_per_unit: {
          required: requiredIf(function () {
            return this.checkAddress == 1;
          }),
        },
        limit_secondary_telephone: {
          required: requiredIf(function () {
            return this.checkTelephone == 1;
          }),
        },
        time_lock_pin: {
          required: requiredIf(function () {
            return this.form.require_pin_login == 1;
          }),
        },
        term_and_con_url: {
          isValidUrl: (val, form) => isValidUrl(val, "term", form),
          required: requiredIf(function () {
            return this.form.is_term_and_con_url == 1;
          }),
        },
        right_request_url: {
          isValidUrl: (val, form) => isValidUrl(val, "right", form),
          required: requiredIf(function () {
            return this.form.is_right_request_url == 1;
          }),
        },
        client_id: {
          required: requiredIf(function () {
            return this.form.enable_single_sign_on_azure_active_directory == 1;
          }),
        },
        azure_endpoint: {
          isValidUrl: (val, form) => isValidUrl(val, "azure", form),
          required: requiredIf(function () {
            return this.form.enable_single_sign_on_azure_active_directory == 1;
          }),
        },
      },
      rfmScale: {
        $each: {
          rfm_range_rate: {
            $each: {
              st_range: {
                required,
              },
              ed_range: {
                required: requiredIf(function (val, index) {
                  return (
                    this.form.enable_single_sign_on_azure_active_directory == 1
                  );
                }),
              },
            },
          },
        },
      },
      setting: {
        profile_complete_description: {
          required: requiredIf(function () {
            return this.setting.display_profile_complete == 1;
          }),
        },
      },
    };
  },
  created() {
    this.getList();
    this.getautomation();
  },
  watch: {
    tabIndex(val) {
      if (val == 1) this.getRFMData();
    },
  },
  computed: {},
  methods: {
    async getautomation() {
      const response = await this.axios(
        `/marketingautomation/complete_profile/list`
      );
      this.automationList = response.data.detail;
    },
    checkText(e) {
      let ew = e.key;
      if (!/[A-Za-z0-9/ ]/.test(ew)) return e.preventDefault();
    },
    async getList() {
      this.$bus.$emit("showLoading");
      const response = await this.axios(`/Coupon/get_coupon_setting`);
      this.checkAddress = response.data.detail.address_per_unit == "" ? 0 : 1;
      this.checkTelephone =
        response.data.detail.limit_secondary_telephone >= 0 &&
        response.data.detail.limit_secondary_telephone != ""
          ? 1
          : 0;
      this.form = response.data.detail;
      this.$bus.$emit("hideLoading");
    },
    async getRFMData() {
      if (this.rfmScale.length == 0) {
        this.isRFMLoading = false;
        const resp = await this.$report(`/dashboard/RFM/rate_scale`);
        for (const n of resp.data.detail) {
          var i = 0;
          for (const m of n.rfm_range_level) {
            i++;

            if (m.ed_range == 0 && n.rfm_range_level.length == i) {
              m.ed_range = "";
            }
          }
        }
        this.rfmScale = resp.data.detail;
        this.isRFMLoading = true;
      }
    },
    async handleCalPeriod() {
      this.isRFMLoading = false;
      this.$bus.$emit("showLoading");
      const resp = await this.$report(
        `/dashboard/RFM/calculate_rate_scale/${this.form.calculate_period}`
      );
      this.$bus.$emit("hideLoading");
      if (!resp.data.detail) {
        this.isRFMLoading = true;
        return this.errorAlert("There is no data in the calculation.");
      }
      for (const n of resp.data.detail) {
        var i = 0;
        for (const m of n.rfm_range_level) {
          i++;

          if (m.ed_range == 0 && n.rfm_range_level.length == i) {
            m.ed_range = "";
          }
        }
      }
      this.isRFMLoading = true;
      this.rfmScale = resp.data.detail;
    },
    clearField(val, keys) {
      if (val == 0) {
        keys.forEach((key) => {
          this.form[key] = "";
        });
      }
    },
    deleteRangeScale(n, index) {
      this.rfmScale[index].rfm_range_rate.splice(n, 1);
      this.rfmScale[index].rfm_range_rate = this.rfmScale[
        index
      ].rfm_range_rate.map((el, a) => {
        return { ...el, rate_scale: a + 1 };
      });
    },
    addRangeScale(index) {
      this.rfmScale[index].rfm_range_rate.push({
        rate_scale: this.rfmScale[index].rfm_range_rate.length + 1,
        st_range:
          this.rfmScale[index].rfm_range_rate[
            this.rfmScale[index].rfm_range_rate.length - 1
          ].ed_range + 0.1,
        ed_range: null,
      });
    },
    async submit() {
      if (this.tabIndex == 2) return this.$refs.settingRedemtion.save();
      this.$v.form.$touch();
      this.$v.setting.$touch();
      this.$v.rfmScale.$touch();

      if (this.$v.rfmScale.$error) {
        if (this.$v.rfmScale.$each[0].$error) this.tabIndex = 0;
        else if (this.$v.rfmScale.$each[1].$error) this.tabIndex = 1;
        else if (this.$v.rfmScale.$each[2].$error) this.tabIndex = 2;
        else if (this.$v.rfmScale.$each[3].$error) this.tabIndex = 3;

        // this.tabIndex = this.$v.rfmScale.$each.filter((el) => el.$error);
      }
      if (
        this.$v.form.$error ||
        this.$v.setting.$error ||
        this.$v.rfmScale.$error
      ) {
        return;
      }
      this.$bus.$emit("showLoading");
      let rfmPayload = JSON.parse(JSON.stringify(this.rfmScale));
      for (const n of rfmPayload) {
        var i = 0;
        for (const m of n.rfm_range_level) {
          i++;

          if (!m.ed_range && n.rfm_range_level.length == i) {
            m.ed_range = 0;
          }
        }
      }
      this.saveProfileComplete();
      const rfmRes = await this.$report.post(
        `/dashboard/RFM/new_rate_scale`,
        rfmPayload
      );

      const response = await this.axios.put(`/Coupon/setting`, this.form);

      this.$bus.$emit("hideLoading");
      if (response.data.result) {
        this.successAlert().then(() => {
          this.$router.push(`/setting`);
        });
      } else {
        this.errorAlert(response.data.message);
      }
    },
    async saveProfileComplete() {
      try {
        const result = await this.axios.post(
          `/setting/updatetheme`,
          this.setting
        );
      } catch (error) {
        this.errorAlert(error.message);
      }
    },
    clearAddress(val) {
      if (val == 0) this.form.address_per_unit = "";
      if (val == 1) this.form.address_per_unit = 0;
    },
    clearTelephone(val) {
      if (val == 0) this.form.limit_secondary_telephone = "";
    },
    clearPin(val) {
      if (val == 0) this.form.time_lock_pin = 0;
    },
  },
};
</script>

<style>
.break-ele {
  flex-basis: 100%;
  height: 0;
}
.font-bold {
  font-weight: bold;
}
</style>
